import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import logo from "../img/logo.svg";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <Nav role="navigation" aria-label="main-navigation">
        <Link to="/" title="Logo">
          <img src={logo} alt="Kaldi" />
        </Link>
        <div id="navMenu" className={`${this.state.navBarActiveClass}`}>
          <Link to="/ceremonies">Ceremonies</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </Nav>
    );
  }
};

const Nav = styled.nav`
  background-color: #000;
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 0 20px;

  * {
    color: white;
  }

  a {
    width: 100px;
  }

  div > a {
    margin-left: 10px;
  }
`;

export default Navbar;
