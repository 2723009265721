import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import celebrantLogo from "../img/logo.jpg";
import logo from "../img/logo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <FooterWrap>
        <CelebrantBar>
          <img src={celebrantLogo} alt="Fellowship of celebrants" />
        </CelebrantBar>
      </FooterWrap>
    );
  }
};

const FooterWrap = styled.footer`
  background-color: black;
`;

const Inner = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;

  img {
    width: 100px;
    flex-grow: 0;
  }

  * {
    color: white;
  }
`;

const CelebrantBar = styled.div`
  height: 90px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  img {
    max-height: 100%;
  }
`;

export default Footer;
