import { createGlobalStyle } from "styled-components";

export const Base = createGlobalStyle`
  :root {
    --dark: #073b28;
    --bg: #c7eddf;
    --white: #fff;
    --light: #f5fffb;
    --blue: #05784e;
  }

  * {
    margin: 0;
    padding: 0;
    font-family: 'Lato';
    color: var(--dark);
  }

  body {
    width: 100%;
    overflow-x: hidden;
  }



  .inner {
    width: 720px;
    margin: 0 auto;
    position: relative;

    & > h2 {
      margin-bottom: 20px;
    }

    @media (max-width: 760px) {
      padding: 20px;
      max-width: calc(100% - 40px);
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
  }

  a {
    color: var(--blue);

    &:hover {
      color: var(--dark);
    }
  }

`;
